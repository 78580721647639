import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const MdWrapper = styled.div`
  padding: 0 2em 1em;

  @media (min-width: 450px) {
    padding: 2em;
  }
`;

export default class MarkdownWrapper extends Component {
  static propTypes = {
    md: PropTypes.string.isRequired
  };

  render() {
    const { md } = this.props;
    return (
      <div>
        <MdWrapper className="md-container">
          <ReactMarkdown source={md} />
        </MdWrapper>
      </div>
    );
  }
}
