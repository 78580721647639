import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledLayerWrapper = styled.div`
  border: 1px solid red;
  /* height: 300px; */
  height: 90vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Layer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LayerVideo = styled(Layer)`
  video {
    min-height: 100%;
    width: auto;
    height: auto;
  }
`;

const LayerText = styled.div`
  color: #fff;
  font-size: 4em;
  width: 100%;
  height: 100%;
  text-align: initial;
`;

const fakeDiv = styled.div``;

export default class LayerWrapper extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    return (
      <StyledLayerWrapper>
        <Layer>
          <img src="/hawaii/AdobeStock_197681768_Preview.jpeg" />
        </Layer>
        <LayerVideo>
          {/* <video
            src="/hawaii/AdobeStock_111547021_Video_HD_Preview.mov"
            autoplay
          /> */}
          <video
            width="2048"
            height="1080"
            id="test"
            autoplay={true}
            mute={true}
            loop={true}
            playsinline={true}
          >
            <source
              src="/hawaii/AdobeStock_111547021_Video_HD_Preview.mov"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </LayerVideo>
        <Layer>
          <div>toto test</div>
        </Layer>
      </StyledLayerWrapper>
    );
  }
}
