import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";

import "./FooterInsideEvent.css";

export default class FooterInsideEvent extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    return (
      <footer className="app-footer-wrapper">
        <div className="app-footer app-footer-menu">
          {/* <img
            className="logo"
            src="/img/react-1-logo-svg-vector-white.svg"
            alt="logo"
          /> 
          <div className="app-footer-col">
            <h3>Details</h3>
            <nav>
              <Link to="/documentation">About us (A propos de nous ?)</Link>
              <a
                href="https://facebook.github.io/create-react-app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Events
              </a>
              <a
                href="https://reactjs.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn React
              </a>
            </nav>
          </div>
          <div className="app-footer-col">
            <h3>Informations Légales</h3>
            <nav>
              <a
                href="https://reactjs.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                CGU / CGV
              </a>
              <a
                href="https://facebook.github.io/create-react-app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookies & données personnelles
              </a>
            </nav>
          </div>
          <div className="app-footer-col">
            <h3>Community</h3>
            <nav>
              <a
                href="https://facebook.github.io/create-react-app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://reactjs.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </nav>
          </div>
        */}
        </div>
        <div className="app-footer app-footer-copyright">
          <div className="app-footer-logo">
            <img src="/img/inside-event-logo.svg" alt="KOAJI Studio" />
          </div>
          <div>Copyright © 2019 Inside Event SAS.</div>
        </div>
      </footer>
    );
  }
}
