import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

console.log("NODE_ENV", process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {
  import(".routeLoaderDevSpy");
}

// console.log("App", App);
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// ---------------------------------------------

// import React, { Component } from "react";
// import { render } from "react-dom";
// import { Map, TileLayer } from "react-leaflet";

// const stamenTonerTiles =
//   "http://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}.png";
// const stamenTonerAttr =
//   'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
// const mapCenter = [39.9528, -75.1638];
// const zoomLevel = 12;

// export default class App extends Component {
//   render() {
//     return (
//       <div>
//         <Map center={mapCenter} zoom={zoomLevel}>
//           <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
//         </Map>
//       </div>
//     );
//   }
// }

// render(<App />, document.getElementById("root"));

// ---------------------------------------------

// import React, { Component } from "react";
// import ReactDOM from "react-dom";
// import { Map, TileLayer } from "react-leaflet";

// import MapboxLayer from "./MapboxLayer";
// // import "./styles.css";

// const MAPBOX_ACCESS_TOKEN =
//   "pk.eyJ1Ijoibmtva2xhIiwiYSI6ImNrMGN3NTVkMjAxa2czZHJ6NXJ6cThmN3cifQ.NENKGZxOa3m1Q4qnyLku0Q";

// export default class CartoMabox extends Component {
//   state = {
//     center: [51.505, -0.091],
//     zoom: 13
//   };

//   render() {
//     return (
//       <div>
//         <Map center={this.state.center} zoom={this.state.zoom}>
//           <MapboxLayer
//             accessToken={MAPBOX_ACCESS_TOKEN}
//             style="mapbox://styles/mapbox/streets-v9"
//           />
//         </Map>
//       </div>
//     );
//   }
// }

// const rootElement = document.getElementById("root");
// ReactDOM.render(<CartoMabox />, rootElement);
