import "intersection-observer"; // optional polyfill

import React, { Component } from "react";
// import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Observer from "@researchgate/react-intersection-observer";

import { Button } from "components";

import "./LayoutHeader.css";

const md = `![Inside Event](/img/inside-event-logo.svg)
# JSconf Hawai’i 2020
La JSconf Hawai’i ouvre les portes de sa seconde Edition du 5 au 7 février 2020.  
Alors si tu veux retrouver ton âme de freelancer, respirer quelques jours en mode Digital Nomad et tripper façon coloc’ avec d’autres modern-entrepreneurs, viens avec nous…  

Inside Event est indépendant de l'organisation des conférences, notre focus c'est ton voyage, ton expérience…  
On organise. Tu kiffes. Direction : Honolulu !!!
`;

export default class LayoutHeader extends Component {
  static propTypes = {};

  options = {
    onChange: this.handleIntersection
    // root: "#scrolling-container",
    // rootMargin: "0% 0% -25%"
  };

  handleIntersection(event) {
    console.log("handleIntersection >", event.isIntersecting);
  }

  handleClick = () => {
    const goUrl = "https://forms.gle/XzT8ukH8dqrTFx9q6";
    window.location.href = goUrl;
  };

  render() {
    return (
      <Observer {...this.options}>
        <section className="layout-header-wrapper">
          <div className="layout-header">
            <div className="text">
              <ReactMarkdown source={md} />
            </div>

            <p>
              <Button onClick={this.handleClick}>Je veux en être !</Button>
            </p>
          </div>
        </section>
      </Observer>
    );
  }
}
