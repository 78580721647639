import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";

import "./HeaderInsideEvent.css";

export default class HeaderInsideEvent extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    return (
      <header className="app-header-wrapper">
        <div className="app-header">
          <div>
            <h1>
              {/* <img
                className="logo"
                src="/img/react-1-logo-svg-vector-white.svg"
                alt="logo"
              /> */}
              <img
                className="logo"
                src="/img/inside-event-logo.svg"
                alt="Inside Event"
              />
              {/* Inside Event Logo */}
            </h1>
            {/* Hawaii 2020 */}
          </div>
          <nav className="app-header-nav">
            <Link to="/">Hawai’i 2020</Link>
            <Link to="/events">Events</Link>
            <Link to="/about-us">About US</Link>
            {/* <a
              href="https://github.com/nkokla/create-react-app-jcod"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a> */}
          </nav>
        </div>
      </header>
    );
  }
}
