import React, { Fragment, PureComponent } from "react";
import JcodParser from "jcod-react-parser";
import { Link, Route, Switch } from "react-router-dom";

import { LayerWrapper } from "components";
import * as templates from "templates";

import {
  HeaderInsideEvent,
  LayoutHeader,
  FooterInsideEvent
} from "./components";

import { withRouteContext } from "react-router-json-routes-loader";
// import { withRouteContext } from "extra/react-router-json-routes-loader";

const parserOption = {
  htmlElement: true,
  // customElement: true,
  // allowElements: ['div', 'popup-info'],
  allowElements: ["popup-info"]
};

class Layout extends PureComponent {
  render() {
    const { dataRoute, path, userProps, RouteProvider } = this.props;
    console.log("dataRoute >>", dataRoute);
    return (
      <Fragment>
        <HeaderInsideEvent />
        <LayoutHeader buttonString="Yhea yhea mano !" />
        {/* <LayerWrapper /> */}
        {/* {userProps} ::: {path}
        <hr /> */}

        <section className="layout-content">
          <JcodParser
            components={templates}
            data={dataRoute.jcodContent}
            options={parserOption}
          />
        </section>

        <FooterInsideEvent />
      </Fragment>
    );
  }
}

export default withRouteContext(Layout, Route, Switch);
